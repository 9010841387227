import { IconNames } from '@/components/Icon/IconsDict';

export interface IMenuItem {
  menuId?: string;
  label: string;
  key: string;
  iconName?: IconNames | null;
  children?: IMenuItem[] | null;
  type?: string;
  external?: boolean;
}

export const menuItems: IMenuItem[] = [
  {
    label: 'Events',
    key: '/app/events',
    iconName: 'icon_home',
  },
  {
    label: 'Organizations',
    key: '/app/organizations',
    iconName: 'organizations',
  },
  {
    menuId: 'access control',
    label: 'Access Control',
    key: '/app/access_control',
    type: 'group',
    iconName: 'users',
    children: [
      { label: 'Applications', key: '/app/applications' },
      { label: 'Users', key: '/app/users' },
      { label: 'Integrations', key: '/app/integrations' },
    ],
  },
  {
    menuId: 'configurations',
    label: 'Configurations',
    key: '/app/configurations',
    type: 'group',
    iconName: 'applications',
    children: [
      { label: 'Session Formats', key: '/app/session_formats' },
      { label: 'Subsession Formats', key: '/app/subsession_formats' },
      { label: 'Poster Formats', key: '/app/poster_formats' },
      { label: 'Social Media Types', key: '/app/social_media_types' },
    ],
  },
  {
    menuId: 'content management',
    label: 'Content Management',
    key: '/app/content_management',
    type: 'group',
    iconName: 'pencil',
    children: [
      { label: 'Topics', key: '/app/content_topics' },
      { label: 'Categories', key: '/app/content_categories' },
      { label: 'Tags', key: '/app/content_tags' },
    ],
  },
  {
    label: 'Data Cleanup Logs',
    key: '/app/datacleanuplogs',
    iconName: 'logs',
  },
  {
    label: 'Technical Support',
    key: '/app/technical_support',
    iconName: 'support',
  },
  {
    label: 'API Reference',
    key: 'https://docs.fuzion.freeman.com',
    iconName: 'integrations',
    external: true,
  },
];
