import { Col, Form, Row } from 'antd';
import { GeneralErrorAlert, SuccesAlert } from '@/features/technicalSupport/TechnicalSupport.style';
import React, { useState } from 'react';
import { SelectInput, TextArea, TextInput } from '@/components/Form';

import { Button } from '@/components/Button';
import { RequiredFieldLabel } from '@/components/Form/RequiredFieldLabel';
import { RuleType } from 'rc-field-form/lib/interface';
import { axios } from '@/lib/axios';
import dompurify from 'dompurify';
import formFieldData from './TechnicalSupport.data';

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
};

interface Values {
  [key: string]: string;
}
interface Field {
  id: string;
  label?: string;
  type: string;
  required?: boolean | string;
  options?: Array<Option> | null;
  helpText?: string | null;
  placeHolder?: string;
  validationMessage?: string;
  pattern?: RegExp;
}

const TechnicalSupportForm: React.FC = () => {
  const [form] = Form.useForm();
  const sanitizer = dompurify.sanitize;

  const [generalErrorMessage, setGeneralErrorMessage] = useState('');
  const [successfulSubmission, setSuccessfulSubmission] = useState(''); // Toggle for success alert message visibility
  const [processingSubmit, setProcessingSubmit] = useState(false); // Toggle for disabling submit button for form

  const emptyOption = { value: '', label: '' };

  /**
   * Sets the error message that will appear at the beginning of the form and changes the focus to that message.
   *
   * @param {string} errorMessage The message that will appear inside the alert.
   */
  const setGeneralError = (errorMessage: string) => {
    setSuccessfulSubmission('');
    setGeneralErrorMessage(errorMessage);

    // Change focus to the top most error on the page
    const errorElement = document.getElementById('generalError');

    if (errorElement) {
      errorElement.focus();
    }
  };

  /**
   * Sets the success message that will appear at the beginning of the form and changes the focus to that message.
   *
   * @param {string} successMessage The message that will appear inside the alert.
   */
  const setGeneralSuccess = (successMessage: string) => {
    setSuccessfulSubmission(successMessage);
    // Change focus to the top most alert on the page
    const successElement = document.getElementById('generalSuccess');

    if (successElement) {
      successElement.focus();
    }
  };

  const submitSupportForm = (values: Values) => {
    setProcessingSubmit(true);

    const operatingSystem =
      formFieldData.operatingSystemOptions.find((item) => item.value === values.operatingSystem) ??
      emptyOption;
    const browser =
      formFieldData.browserOptions.find((item) => item.value === values.browser) ?? emptyOption;
    const device =
      formFieldData.deviceOptions.find((item) => item.value === values.device) ?? emptyOption;

    // Clean up the data before sending to API
    const cleanFullName = sanitizer(values.fullName);
    const cleanEmail = sanitizer(values.emailAddress);
    const cleanDescription = sanitizer(values.description);

    const msg = {
      name: cleanFullName,
      email: cleanEmail,
      subject: `Fuzion Support`,
      description: `<p>This attendee is asking for technical help with the following information:</p>
                <ul>
                    <li>Business Email Address: ${cleanEmail}</li>
                    <li>Full Name: ${cleanFullName}</li>
                    <li>Device Type: ${device.label} [${values.device}]</li>
                    <li>Browser: ${browser.label} [${values.browser}]</li>
                    <li>Operating System: ${operatingSystem.label} [${values.operatingSystem}]</li>
                    <li>Description: ${cleanDescription}</li>
                </ul>`,
    };

    axios
      .post(`/tickets/v2`, msg)
      .then(() => {
        setGeneralError('');
        setGeneralSuccess(
          'Thank you for getting in touch. We’ll respond at our earliest convenience. '
        );
      })
      .catch((err) => {
        setGeneralError(
          `Error occured. Server responded with: ${err.message} Please try again later.`
        );
      });

    setProcessingSubmit(false);
  };

  /**
   * Renders a form field based on the form field data provided.
   *
   * @param {Object} field
   * @param {string} key
   */
  const renderField = (field: Field) => {
    if (!field.type || ['string', 'email'].includes(field.type)) {
      return (
        <Col xs={24} md={10} key={field.id}>
          <Form.Item
            name={field.id}
            label={
              field.required && field.label ? (
                <RequiredFieldLabel label={field.label} />
              ) : (
                `${field.label} (Optional)`
              )
            }
            rules={[
              { required: !!field.required, message: field.helpText ?? undefined },
              {
                type: field.type as RuleType,
                message: field.validationMessage,
              },
              {
                pattern: field.pattern,
                message: field.validationMessage,
              },
            ]}
          >
            <TextInput placeholder={field.placeHolder} disabled={!!successfulSubmission} />
          </Form.Item>
        </Col>
      );
    } else if (field.type === 'dropdown') {
      return (
        <Col xs={24} md={10} key={field.id}>
          <Form.Item
            shouldUpdate
            name={field.id}
            label={
              field.required && field.label ? (
                <RequiredFieldLabel label={field.label} />
              ) : (
                `${field.label} (Optional)`
              )
            }
            rules={[
              { required: !!field.required, message: field.helpText ?? undefined },
              {
                type: 'string',
                message: 'A description of your technical issue is required.',
              },
            ]}
          >
            <SelectInput
              showSearch={false}
              placeholder={field.placeHolder}
              options={field.options ?? [emptyOption]}
              disabled={!!successfulSubmission}
            />
          </Form.Item>
        </Col>
      );
    }
  };

  const renderFields = () => {
    const formFieldsData = formFieldData?.formFields;
    if (!formFieldsData) return null;

    const fieldsToRender: React.ReactElement[] = [];
    formFieldsData.forEach((field) => {
      const view = renderField(field);
      if (view) {
        fieldsToRender.push(view);
      }
    });
    return fieldsToRender;
  };

  return (
    <Form
      id="technical-support-form"
      layout="vertical"
      requiredMark={false}
      form={form}
      style={{ maxWidth: 600 }}
      onFinish={submitSupportForm}
      initialValues={formFieldData.initialValues}
    >
      <Row justify="space-between">{renderFields()}</Row>
      <Row>
        <Col span={24}>
          <Form.Item
            name="description"
            label={<RequiredFieldLabel label="Description" />}
            rules={[
              {
                required: true,
                message: 'Please enter your technical issue here.',
              },
              {
                type: 'string',
                message: 'A description of your technical issue is required.',
              },
            ]}
          >
            <TextArea
              rows={10}
              placeholder="Enter your technical issue here with as much detail as possible..."
              disabled={!!successfulSubmission}
            />
          </Form.Item>
        </Col>
      </Row>
      {successfulSubmission && (
        <SuccesAlert id="generalSuccess" role="alert">
          {successfulSubmission}
        </SuccesAlert>
      )}
      {generalErrorMessage && (
        <GeneralErrorAlert id="generalError" role="alert">
          {generalErrorMessage}
        </GeneralErrorAlert>
      )}
      {!successfulSubmission && (
        <Row justify="end">
          <Col>
            <Button id="submit-button" type="primary" htmlType="submit" disabled={processingSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default TechnicalSupportForm;
